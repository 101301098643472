@import url(//fonts.googleapis.com/css?family=Muli:wght@400;600;700;800;900&display=swap);
@import url(//fonts.googleapis.com/css?family=Muli);
@import url(//fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
}

html {
  font-size: 16px !important;
  color: white !important;
  background-color: #121212 !important;
}

/* label {
   color: #9e9e9e !important;
   font-size: 16px !important;
 }

 input{
  font-size: 16px !important;
 }

 button{
   font-size: 16px !important;
 } */

/* Global css */

.container-form {
  background-color: #232323;
  padding: 15px;
  border-radius: 8px;
  min-height: 100vh;
}

.container-home {
  padding: 15px;
}

/* temporal */

/* .MuiStepLabel-label.MuiStepLabel-active {
   border-bottom: 1px solid #50C1EE;
   padding-bottom: 10px;
   padding-right: 50px;
   padding-left: 50px;
 } */

.bgtext:after {
  content: 'Background text';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.comunContainer {
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;
}

.comunContainerFlex {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.comunContainerHide {
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;
}

.centerContainer {
  width: 100%;
  text-align: center;
}

/* Global css */

.card .card-action:last-child {
  border-radius: 10px !important;
}

.card-blue {
  background-color: #16b1f3;
  border-radius: 10px !important;
  font-size: 15px;
}

.card-blue-title {
  padding-left: 10px !important;
  padding-top: 30px !important;
}

.card-blue-content {
  padding: 25px 10px 5px 25px !important;
}

.card-gray {
  background-color: #2e2e2e !important;
  border-radius: 10px !important;
}

.label-big {
  font-size: 40px !important;
}

.btn-yellow {
  background-color: #f5d13d !important;
  width: 100%;
  height: 50px;
  font-weight: bold;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.icon-shadow {
  border: 4px;
}

/* Begin scroll */

/* width */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  background: #232323;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
  height: 10px !important;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* End scroll */

/* Begin Stepper My Account */

.stepper-title {
  font-size: 16px !important;
  padding-bottom: 10px;
  color: white;
}

/* End Stepper  My Account * */

.title-yellow {
  height: 25px;
  border-radius: 5px;
  background-color: #f5d13d;
  color: #2c2c2c;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}

.title-users {
  width: 98%;
  margin-right: 10px;
}

.note-yellow {
  text-align: justify;
  margin-left: 2rem;
  margin-right: 2rem;
  color: #f5d13d;
  font-size: 15px;
  font-style: italic;
}

.custom-collapsible {
  border-radius: 5px !important;
  border: none !important;
}

.custom-collapsible-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.18) !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 16px !important;
}

.custom-collapsible-body {
  border: none !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.18) !important;
}

.btn-primary {
  border-radius: 5px;
  background-color: #16b1f3;
  width: 100%;
  height: 50px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.btn-invoice {
  border-radius: 5px;
  background-color: #16b1f3;
  width: 50%;
  height: 50px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.container-title {
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18, 18, 18, 0.3);
  color: #2cc63e;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .card-columns-user {
} */

/*Estilo switch */

.switch label .inputCheck[type='checkbox']:checked + .lever {
  background-color: #9ff781;
}

.switch label .inputCheck[type='checkbox']:checked + .lever:after {
  background-color: #40ff00;
}

.switch label .lever {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 14px;
  background-color: rgba(69, 90, 100, 0.4);
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label .lever:after {
  background-color: #d8d8d8;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.inputCheck[type='checkbox']:checked:not(:disabled) ~ .lever:active::before,
.inputCheck[type='checkbox']:checked:not(:disabled).tabbed:focus
  ~ .lever::before {
  transform: scale(2.4);
  background-color: rgba(206, 147, 216, 0.4);
}

/*Estilo Circle de iconos*/

.circle {
  border-radius: 50%;
  background: #82fa58;
  color: black;
}

/*Estilos para MyAccount*/

.toolip-help {
  color: #2dbf3e;
}

.scrollPanel {
  width: 100%;
  border: none;
  overflow: auto;
  position: relative;
  max-height: 1000px;
  border-radius: 5px;
  background-color: transparent !important;
  margin-right: 10px;
}

.custom-collapse {
  background-color: rgba(255, 255, 255, 0.18);
}

/*CAMBIO FERNANDO RUIZ TOAST STYLE*/

/* Begin styles Toast */

.ct-toast {
  background-color: rgba(95, 93, 93, 0.83) !important;
  color: white !important;
  width: 70% !important;
  justify-content: left !important;
}

/* End styles Toast */

.swal2-container {
  z-index: 99999 !important;
}

.swal2-popup {
  background-color: rgb(56 56 56) !important;
  color: white !important;
  max-width: 30%;
  padding: 1%;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
}

.swal2-content {
  color: white !important;
}

.swal2-title {
  color: white !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(12, 12, 12, 0.8) !important;
}

.swal2-styled.swal2-confirm {
  background-color: transparent !important;
}

.swal2-styled.swal2-cancel {
  background-color: transparent !important;
}

.swal2-styled:focus {
  box-shadow: none !important;
  border: transparent !important;
}
.swal2-actions {
  flex-direction: row-reverse !important;
}


/* Configuración estilos de sweetAlert para carga masiva empleados*/
.content-bulkLoad {
  padding: 5.516px !important;
  text-align: left;
  text-align: initial;
};



.select-wrapper input.select-dropdown {
    color: aliceblue;
}

.MuiAutocomplete-option {
    background-color: #222222;
    color: #FAFAFA;
}

.MuiAutocomplete-option:hover {
    background-color: #4A4A4A !important;
}

.MuiAutocomplete-option:focus {
    background-color: #c00f0f !important;
}

.MuiAutocomplete-noOptions {
    background-color: #222222;
    color: #FAFAFA !important;
}

.MuiAutocomplete-listbox{
    background-color: #222222;
}

.rectangule-hover > svg > path {
  fill: transparent !important;
}

.custom-legend {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
}

.custom-legend li {
  display: flex;
  align-items: baseline;

}

.legend-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;

  
}


.legend-text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.069px;
  padding-left: 10px;
  margin:0;
  margin-bottom: 10px;
  
}

.legend-text span{
  font-size: 12px;
  letter-spacing: 0.051px;
  display: block;
  font-weight: 300;
  padding-top: 4px;
} 
.container-custom {
  background-color: #FAFAFA;
  border-radius: 2px;
  box-shadow: 0px 0px 4px 0px #272727;
  width: 178px;
  padding: 7px;
}

.custom-name {
  color: #272727;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-top: 3px;
  padding-left: 5px;
  margin: 0px;
}
.custom-name-sub {
  color: #272727;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-top: 3px;
  padding-left: 5px;
  margin: 0px;
}

.custom-description {
  line-height: 15px;
  padding-top: 5px;
  padding-left: 10px;
  color: #272727;
  font-size: 13px;
  font-weight: 700;
  display: block;
}
.createInvoice {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.18);
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 0.8%;
}
.createInvoice:hover {
  cursor: pointer;
}
.cardDetailInvoice {
  width: 98%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  margin-bottom: 12px;
  cursor: pointer;
  padding: 1%;
}
.cardDetailInvoiceSelected {
  width: 98%;
  border-radius: 4px;
  background-color: #16b1f3;
  margin-bottom: 12px;
  cursor: pointer;
  padding: 1%;
  box-shadow: 0 0 7px 0 #16b1f3;
}
.cardDetailInvoiceFullData {
  width: 98%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  margin-bottom: 12px;
  padding: 1%;
}

.cardDetailInvoice:hover {
  border-color: #16b1f3;
  border-style: ridge;
  /*box-shadow: 0 0 7px 0 #16B1F3;*/
}
.cardDetailInvoice:active {
  background-color: rgb(188, 218, 231);
  box-shadow: 0 0 7px 0 #16b1f3;
}
.gridNoSpace {
  display: flex;
  padding-left: 5px;
}
.nameCostumer {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  max-height: 30px;
  overflow: hidden;
}
.dataInvoiceGrid {
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}
.dataNumberInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}
.titleArticleInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 1.3%;
  max-height: 30px;
  overflow: hidden;
}
.titleArticleInvoiceItalic {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  font-style: italic;
}
.totalInvoiceDetail {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  text-align: left;
}
.totalInvoiceGridPaidout {
  color: #2cc63e;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.totalInvoiceGridPending {
  color: #f5d13d;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.totalInvoiceGridExpired {
  color: #ec005f;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.totalInvoiceGridSelected {
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.stateInvoicePaidout {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.stateInvoicePending {
  color: #f5d13d;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.stateInvoiceExpired {
  color: #ec005f;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.stateInvoiceSelected {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.detailInvoiceNumber {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
}
.detailInvoiceInfo {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
}
.buttonFilter {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: transparent;
}
.lineFinder {
  box-sizing: border-box;
  border-bottom-color: solid #848484;
  color: white;
}
.spacingInvoice {
  margin-bottom: 10px;
}
.spacingInvoiceMini {
  margin-bottom: 5px;
}
.searchDocuments {
  color: #ffffff;
}
.lineDivision {
  box-sizing: border-box;
  height: 1px;
  width: 98%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.lineDivisionFields {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.constumerNameDetail {
  color: #f5d13d;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: left;
  margin-top: 3%;
}
.greenTitleInvoice {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  border-radius: 5px;
  height: 36px;
  padding-top: 0.6%;
  background-color: rgba(18, 18, 18, 0.3);
}
.bottomButtonsInvoice {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 72px;
  text-align: center;
  padding-top: 2%;
}
.bottomButtonsInvoiceRevision {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 64px;
  text-align: center;
  padding-top: 0.8%;
}
.bottomButtonsCreateInvoice {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 64px;
  text-align: center;
  padding-top: 0.8%;
}
.tabInfoAdicional {
  border-radius: 5px;
  background-color: #2cc63e;
  height: 37px;
  width: 100%;
  color: #393939;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  padding-top: 1%;
}
.buttonGroupInovice {
  border-radius: 3.6px;
  background-color: rgba(255, 255, 255, 0.2);
  height: 34px;
  text-align: center;
}
.buttonGreenInvoice {
  height: 28.33px;
  border-radius: 4px;
  background-color: #2cc63e;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 300;
  width: 48%;
  border: 0px;
  cursor: pointer;
  margin-top: 3px;
}
.buttonGrayInvoice {
  height: 28.33px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 300;
  width: 48%;
  border: 0px;
  cursor: pointer;
  margin-top: 3px;
}
.miniLetterInvoice {
  color: rgba(255, 255, 255, 0.5);
  font-family: Muli;
  font-size: 10px;
}
.textButtonsInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}

.buttonInvoiceBehavior {
  cursor: pointer !important;
  color: #ffffff;
  margin-top: 5;
}

.buttonInvoiceConfirm {
  height: 54px;
  width: 98%;
  border-radius: 4px;
  background-color: #16b1f3;
  border: #16b1f3;
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
}
.buttonInvoiceConfirm:hover {
  box-shadow: 0 0 7px 0 rgb(125, 172, 192);
}
.buttonSecondary {
  height: 54px;
  width: 98%;
  border-radius: 4px;
  background-color: #7dd1f8;
  border: #7dd1f8;
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
}
.buttonSecondary:hover {
  box-shadow: 0 0 7px 0 rgb(125, 172, 192);
}

.modalEmailText {
  padding-left: 9px;
  padding-bottom: 20px;
  padding-top: 6px;
}

.modalEmail {
  padding-bottom: 20px;
  padding-top: 6px;
}

.modalStyleInvoice {
  border-radius: 4px;
  background-color: #2d2d2d;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  color: white;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 1%;
}
.fieldsReadMini {
  color: rgba(255, 255, 255, 0.5);
  font-family: Muli;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.fieldsReadContainer {
  padding-top: 7px;
}
.fieldsReadBig {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  padding-top: 0.6%;
}
.paddingLeft {
  padding-left: 5px;
}
.titleAdicitional {
  height: 36px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18, 18, 18, 0.3);
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  padding-top: 0.8%;
}
.cardItemInvoice {
  height: 190px;
  width: 99%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  fill: Solid;
}
.cardDetailInfoAditional {
  background-color: #5e5e5e;
  border-radius: 5px;
  padding: 2.9%;
}

.circlesCategories {
  height: 19px;
  width: 19px;
  border-radius: 9.5px;
  padding-top: 1%;
}

.cardCostumerInvoice {
  background-color: #3b3b3b;
  border-radius: 4px;
  padding: 1%;
  padding-left: 3%;
}
.panelOverflowInvoice {
  overflow-y: auto;
  overflow-x: hidden;
}

.panelOverflowInvoiceCostumer {
  overflow: hidden !important;
  padding-right: 5px;
  height: 100%;
}
.panelVisibleInvoice {
  display: block;
}
.panelInvisibleInvoice {
  display: none;
}
.panelColorInvoice {
  color: #ffffff;
}
.iconColorInvoice {
  color: #2cc63e;
}
.aditionalInfoTitle {
  height: 36px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18, 18, 18, 0.3);
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  padding-top: 0.8%;
}
.totalRevision {
  color: #2cc63e;
  font-family: Muli;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
  text-align: center;
}
.commentsRevision {
  color: #ffffff;
  font-family: Muli;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
.dateRevision {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
  padding-right: 2%;
}
.backgroundRevision {
  background-color: #4b4b4b;
  border-radius: 5px;
  padding: 1%;
}
.invoiceConfirmation {
  position: absolute;
  width: 100%;
  top: 70px;
  left: 0px;
  background-color: darkgrey;
  z-index: 1000;
  background-color: rgba(18, 18, 18, 0.8);
}
.letterTextSearch.MuiInput-underline {
  color: #ffffff;
  border-color: #4b4b4b;
}
.letterTextSearch.MuiInput-underline::before {
  border-color: #4b4b4b;
}
.letterTextSearch.MuiInput-underline::hover {
  border-color: #4b4b4b;
}
.letterTextSearch.MuiInput-underline::after {
  border-color: #4b4b4b;
}
.fenomenalWhite {
  color: #ebebeb;
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
}
.fenomenalWhiteLittle {
  color: #ebebeb;
  font-family: Muli;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.fenomenalBlueLittle {
  color: #16d5f9;
  font-family: Muli;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.fenomenalBlue {
  color: #16d5f9;
  font-family: Muli;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  font-weight: bolder;
}
.imageConfirmation {
  height: 280px;
}
.skipMessage {
  color: #16b1f3;
  font-family: Muli;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: right;
  cursor: pointer;
}
.panelCentered {
  text-align: center;
}
.backInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
}
.validatorOthers {
  color: red;
  font-size: 14px;
  text-align: center;
  padding-top: 2%;
}
.verticalLineInvoiceOther {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 25px;
  margin-right: 30%;
}
.btnFilterGray {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 15px;
  text-align: center;
  height: 30px;
  width: 170px;
  cursor: pointer;
}
.btnFilterBlue {
  border-radius: 4px;
  background-color: #16b1f3;
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 15px;
  text-align: center;
  height: 30px;
  width: 170px;
  cursor: pointer;
}
.colorCalendarRC {
  color: #ebebeb;
  font-family: Muli;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.rc-calendar-input {
  color: #ffffff;
  font-family: Muli;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.rc-calendar-date {
  color: #ffffff;
}
.rc-calendar-date:hover {
  color: #3b3b3f;
}
.rc-calendar-month-select {
  color: #ffffff;
}
.rc-calendar-year-select {
  color: #ffffff;
}
.rc-calendar-disabled-cell .rc-calendar-date {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.11);
}
.rc-calendar-selected-day .rc-calendar-date {
  background-color: #16b1f3;
}
.rc-calendar-today-btn {
  color: #ebebeb;
}
.bottonWarning {
  height: 27.5px;
  width: 186px;
  border-radius: 13.75px;
  background-color: #c7c7c7;
  color: #000000;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding-top: 2px;
  /*line-height: 20px;*/
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
}
.titleWarning {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
}
.detailWarning {
  font-family: Muli;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.disabledByPay {
  color: #5e5e5e !important;
}

.backEmployee {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
}
.buttonEmployeeBehavior {
  cursor: pointer !important;
  color: #ffffff;
  margin-top: 5;
}
.textButtonsEmployee {
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}
.bottomButtonsEmployeeRevision {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 64px;
  text-align: center;
  padding-top: 0.8%;
}
.bottomButtonsCreateEmployee {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-top: 0.8%;
}

.MuiList-root {
  background-color: #121212;
}

ul.MuiList-root li span {
  color: #9e9e9e;
}

ul.dropdown-content.select-dropdown li span:hover {
  background-color: #9e9e9e !important;
}


.MuiMenuItem-root:hover {
    background-color: #4A4A4A !important;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
    padding: 0 !important;
}

.imagen {
  border: solid 4px #16b1f3;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.input-image {
  display: none !important;
  border-color: #424242 !important;
  border: none !important;
  background-color: transparent !important;
}

.container {
  position: relative;
  width: 100%;
  border: 4px solid #16b1f3;
  border-radius: 10px;
  text-align: end !important;
}

.container-img-logo {
  width: 100%;
  background-repeat: no-repeat !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  border-radius: 10px !important;
  background-size: contain !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 200px;
}

.container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Style the button and place it in the middle of the container/image */

.container .btnLogo {
  position: absolute !important;
  padding: 0px;
  margin-right: -56px;
  margin-top: -60px;
  margin-left: -28px;
}

.container input {
  display: none;
}

/* .container .btn>span.MuiIconButton-label {
  width: 30px !important;
} */

