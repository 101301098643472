.imagen {
  border: solid 4px #16b1f3;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.input-image {
  display: none !important;
  border-color: #424242 !important;
  border: none !important;
  background-color: transparent !important;
}

.container {
  position: relative;
  width: 100%;
  border: 4px solid #16b1f3;
  border-radius: 10px;
  text-align: end !important;
}

.container-img-logo {
  width: 100%;
  background-repeat: no-repeat !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  border-radius: 10px !important;
  background-size: contain !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 200px;
}

.container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Style the button and place it in the middle of the container/image */

.container .btnLogo {
  position: absolute !important;
  padding: 0px;
  margin-right: -56px;
  margin-top: -60px;
  margin-left: -28px;
}

.container input {
  display: none;
}

/* .container .btn>span.MuiIconButton-label {
  width: 30px !important;
} */
