.backEmployee {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
}
.buttonEmployeeBehavior {
  cursor: pointer !important;
  color: #ffffff;
  margin-top: 5;
}
.textButtonsEmployee {
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}
.bottomButtonsEmployeeRevision {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 64px;
  text-align: center;
  padding-top: 0.8%;
}
.bottomButtonsCreateEmployee {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-top: 0.8%;
}
