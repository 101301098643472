.container-custom {
  background-color: #FAFAFA;
  border-radius: 2px;
  box-shadow: 0px 0px 4px 0px #272727;
  width: 178px;
  padding: 7px;
}

.custom-name {
  color: #272727;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-top: 3px;
  padding-left: 5px;
  margin: 0px;
}
.custom-name-sub {
  color: #272727;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-top: 3px;
  padding-left: 5px;
  margin: 0px;
}

.custom-description {
  line-height: 15px;
  padding-top: 5px;
  padding-left: 10px;
  color: #272727;
  font-size: 13px;
  font-weight: 700;
  display: block;
}