@import url('//fonts.googleapis.com/css?family=Muli');
@import url('//fonts.googleapis.com/css?family=Roboto');

.createInvoice {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.18);
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 0.8%;
}
.createInvoice:hover {
  cursor: pointer;
}
.cardDetailInvoice {
  width: 98%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  margin-bottom: 12px;
  cursor: pointer;
  padding: 1%;
}
.cardDetailInvoiceSelected {
  width: 98%;
  border-radius: 4px;
  background-color: #16b1f3;
  margin-bottom: 12px;
  cursor: pointer;
  padding: 1%;
  box-shadow: 0 0 7px 0 #16b1f3;
}
.cardDetailInvoiceFullData {
  width: 98%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  margin-bottom: 12px;
  padding: 1%;
}

.cardDetailInvoice:hover {
  border-color: #16b1f3;
  border-style: ridge;
  /*box-shadow: 0 0 7px 0 #16B1F3;*/
}
.cardDetailInvoice:active {
  background-color: rgb(188, 218, 231);
  box-shadow: 0 0 7px 0 #16b1f3;
}
.gridNoSpace {
  display: flex;
  padding-left: 5px;
}
.nameCostumer {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  max-height: 30px;
  overflow: hidden;
}
.dataInvoiceGrid {
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}
.dataNumberInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}
.titleArticleInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 1.3%;
  max-height: 30px;
  overflow: hidden;
}
.titleArticleInvoiceItalic {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  font-style: italic;
}
.totalInvoiceDetail {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  text-align: left;
}
.totalInvoiceGridPaidout {
  color: #2cc63e;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.totalInvoiceGridPending {
  color: #f5d13d;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.totalInvoiceGridExpired {
  color: #ec005f;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.totalInvoiceGridSelected {
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: right;
}
.stateInvoicePaidout {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.stateInvoicePending {
  color: #f5d13d;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.stateInvoiceExpired {
  color: #ec005f;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.stateInvoiceSelected {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}
.detailInvoiceNumber {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
}
.detailInvoiceInfo {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
}
.buttonFilter {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: transparent;
}
.lineFinder {
  box-sizing: border-box;
  border-bottom-color: solid #848484;
  color: white;
}
.spacingInvoice {
  margin-bottom: 10px;
}
.spacingInvoiceMini {
  margin-bottom: 5px;
}
.searchDocuments {
  color: #ffffff;
}
.lineDivision {
  box-sizing: border-box;
  height: 1px;
  width: 98%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.lineDivisionFields {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.constumerNameDetail {
  color: #f5d13d;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  text-align: left;
  margin-top: 3%;
}
.greenTitleInvoice {
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  border-radius: 5px;
  height: 36px;
  padding-top: 0.6%;
  background-color: rgba(18, 18, 18, 0.3);
}
.bottomButtonsInvoice {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 72px;
  text-align: center;
  padding-top: 2%;
}
.bottomButtonsInvoiceRevision {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 64px;
  text-align: center;
  padding-top: 0.8%;
}
.bottomButtonsCreateInvoice {
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  height: 64px;
  text-align: center;
  padding-top: 0.8%;
}
.tabInfoAdicional {
  border-radius: 5px;
  background-color: #2cc63e;
  height: 37px;
  width: 100%;
  color: #393939;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  padding-top: 1%;
}
.buttonGroupInovice {
  border-radius: 3.6px;
  background-color: rgba(255, 255, 255, 0.2);
  height: 34px;
  text-align: center;
}
.buttonGreenInvoice {
  height: 28.33px;
  border-radius: 4px;
  background-color: #2cc63e;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 300;
  width: 48%;
  border: 0px;
  cursor: pointer;
  margin-top: 3px;
}
.buttonGrayInvoice {
  height: 28.33px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: 300;
  width: 48%;
  border: 0px;
  cursor: pointer;
  margin-top: 3px;
}
.miniLetterInvoice {
  color: rgba(255, 255, 255, 0.5);
  font-family: Muli;
  font-size: 10px;
}
.textButtonsInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}

.buttonInvoiceBehavior {
  cursor: pointer !important;
  color: #ffffff;
  margin-top: 5;
}

.buttonInvoiceConfirm {
  height: 54px;
  width: 98%;
  border-radius: 4px;
  background-color: #16b1f3;
  border: #16b1f3;
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
}
.buttonInvoiceConfirm:hover {
  box-shadow: 0 0 7px 0 rgb(125, 172, 192);
}
.buttonSecondary {
  height: 54px;
  width: 98%;
  border-radius: 4px;
  background-color: #7dd1f8;
  border: #7dd1f8;
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
}
.buttonSecondary:hover {
  box-shadow: 0 0 7px 0 rgb(125, 172, 192);
}

.modalEmailText {
  padding-left: 9px;
  padding-bottom: 20px;
  padding-top: 6px;
}

.modalEmail {
  padding-bottom: 20px;
  padding-top: 6px;
}

.modalStyleInvoice {
  border-radius: 4px;
  background-color: #2d2d2d;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  color: white;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 1%;
}
.fieldsReadMini {
  color: rgba(255, 255, 255, 0.5);
  font-family: Muli;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.fieldsReadContainer {
  padding-top: 7px;
}
.fieldsReadBig {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  padding-top: 0.6%;
}
.paddingLeft {
  padding-left: 5px;
}
.titleAdicitional {
  height: 36px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18, 18, 18, 0.3);
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  padding-top: 0.8%;
}
.cardItemInvoice {
  height: 190px;
  width: 99%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  fill: Solid;
}
.cardDetailInfoAditional {
  background-color: #5e5e5e;
  border-radius: 5px;
  padding: 2.9%;
}

.circlesCategories {
  height: 19px;
  width: 19px;
  border-radius: 9.5px;
  padding-top: 1%;
}

.cardCostumerInvoice {
  background-color: #3b3b3b;
  border-radius: 4px;
  padding: 1%;
  padding-left: 3%;
}
.panelOverflowInvoice {
  overflow-y: auto;
  overflow-x: hidden;
}

.panelOverflowInvoiceCostumer {
  overflow: hidden !important;
  padding-right: 5px;
  height: 100%;
}
.panelVisibleInvoice {
  display: block;
}
.panelInvisibleInvoice {
  display: none;
}
.panelColorInvoice {
  color: #ffffff;
}
.iconColorInvoice {
  color: #2cc63e;
}
.aditionalInfoTitle {
  height: 36px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18, 18, 18, 0.3);
  color: #2cc63e;
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  padding-top: 0.8%;
}
.totalRevision {
  color: #2cc63e;
  font-family: Muli;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
  text-align: center;
}
.commentsRevision {
  color: #ffffff;
  font-family: Muli;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
.dateRevision {
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
  padding-right: 2%;
}
.backgroundRevision {
  background-color: #4b4b4b;
  border-radius: 5px;
  padding: 1%;
}
.invoiceConfirmation {
  position: absolute;
  width: 100%;
  top: 70px;
  left: 0px;
  background-color: darkgrey;
  z-index: 1000;
  background-color: rgba(18, 18, 18, 0.8);
}
.letterTextSearch.MuiInput-underline {
  color: #ffffff;
  border-color: #4b4b4b;
}
.letterTextSearch.MuiInput-underline::before {
  border-color: #4b4b4b;
}
.letterTextSearch.MuiInput-underline::hover {
  border-color: #4b4b4b;
}
.letterTextSearch.MuiInput-underline::after {
  border-color: #4b4b4b;
}
.fenomenalWhite {
  color: #ebebeb;
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
}
.fenomenalWhiteLittle {
  color: #ebebeb;
  font-family: Muli;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.fenomenalBlueLittle {
  color: #16d5f9;
  font-family: Muli;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.fenomenalBlue {
  color: #16d5f9;
  font-family: Muli;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  font-weight: bolder;
}
.imageConfirmation {
  height: 280px;
}
.skipMessage {
  color: #16b1f3;
  font-family: Muli;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: right;
  cursor: pointer;
}
.panelCentered {
  text-align: center;
}
.backInvoice {
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
}
.validatorOthers {
  color: red;
  font-size: 14px;
  text-align: center;
  padding-top: 2%;
}
.verticalLineInvoiceOther {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 25px;
  margin-right: 30%;
}
.btnFilterGray {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.11);
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 15px;
  text-align: center;
  height: 30px;
  width: 170px;
  cursor: pointer;
}
.btnFilterBlue {
  border-radius: 4px;
  background-color: #16b1f3;
  color: #ffffff;
  font-family: Muli;
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 15px;
  text-align: center;
  height: 30px;
  width: 170px;
  cursor: pointer;
}
.colorCalendarRC {
  color: #ebebeb;
  font-family: Muli;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.rc-calendar-input {
  color: #ffffff;
  font-family: Muli;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.rc-calendar-date {
  color: #ffffff;
}
.rc-calendar-date:hover {
  color: #3b3b3f;
}
.rc-calendar-month-select {
  color: #ffffff;
}
.rc-calendar-year-select {
  color: #ffffff;
}
.rc-calendar-disabled-cell .rc-calendar-date {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.11);
}
.rc-calendar-selected-day .rc-calendar-date {
  background-color: #16b1f3;
}
.rc-calendar-today-btn {
  color: #ebebeb;
}
.bottonWarning {
  height: 27.5px;
  width: 186px;
  border-radius: 13.75px;
  background-color: #c7c7c7;
  color: #000000;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding-top: 2px;
  /*line-height: 20px;*/
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
}
.titleWarning {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
}
.detailWarning {
  font-family: Muli;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.disabledByPay {
  color: #5e5e5e !important;
}
