
.rectangule-hover > svg > path {
  fill: transparent !important;
}

.custom-legend {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
}

.custom-legend li {
  display: flex;
  align-items: baseline;

}

.legend-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;

  
}


.legend-text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.069px;
  padding-left: 10px;
  margin:0;
  margin-bottom: 10px;
  
}

.legend-text span{
  font-size: 12px;
  letter-spacing: 0.051px;
  display: block;
  font-weight: 300;
  padding-top: 4px;
} 